.footer {
  bottom: 0;
  width: 100%;
  background-color: #e6c629;
  color: white;
  text-align: center;
  padding: 10px 0;
  z-index: 1000;
}

@media (max-width: 640px) {
  .footer {
    /* position: fixed; 
        bottom: 0;
        width: 100%;
        background-color: #e6c629;  
        color: white;               
        text-align: center;        
        padding: 10px 0;          
        z-index: 1000;   */
    display: none;
  }
}
