.checkout-form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  margin-bottom: 20px;
  padding: 20px;
}

.checkout-form label {
  font-weight: bold;
  display: block;
  margin-bottom: 10px;
  font-size: 16px;
}

.checkout-form input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f7f7f7;
  font-family: "Roboto", sans-serif;
}

.checkout-form h2 {
  grid-column: 1 / -1;
  margin-bottom: 20px;
  font-size: 24px;
  font-family: "Open Sans", sans-serif;
}

.checkout-actions {
  grid-column: 1 / -1;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}

button[type="button"],
button[type="submit"] {
  background-color: #e6c629;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.3s;
  font-family: "Roboto", sans-serif;
}

button[type="button"]:hover,
button[type="submit"]:hover {
  background-color: #0c2a45;
}
