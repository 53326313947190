.orders {
  position: fixed;
  top: 0;
  right: 0;
  width: 25%;
  height: 100%;
  background-color: #f6f7f9; /* Lighter background for a modern feel */
  border-left: 2px solid #ddd; /* Make border slightly thicker */
  padding: 1.5rem;
  display: none;
  overflow-y: scroll;
  z-index: 100;
  font-family: "Arial", sans-serif; /* Choose a modern font */
  box-shadow: -5px 0 15px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
}

.close-orders {
  margin-top: 3%;
  margin-right: 3%;
  background-color: #eee;
  color: #0c2a45;
  border: none;
  border-radius: 10%;
  font-size: 14px;
  font-weight: bold;
  padding: 4px 8px;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
}

.close-orders:hover {
  background-color: #1a3d66; /* Darken on hover */
}

.order-item {
  display: flex;
  align-items: center; /* Center items vertically */
  justify-content: space-between; /* Separate image and description */
  padding: 1rem;
  border-bottom: 1px solid #e0e0e0; /* Lighter border color */
  margin-bottom: 1rem;
}

.order-item-img {
  max-width: 80px;
  max-height: 110px;
  object-fit: cover; /* This will ensure that your image covers the width and height without skewing */
  margin-right: 1rem;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  flex: 0 0 auto;
}

.order-item-desc {
  flex: 1; /* Allow the description to take up the remaining space */
  /* Add additional styling for the description here if necessary */
}

.orders-title {
  font-size: 1.8rem; /* Bigger title for emphasis */
  color: #0c2a45;
  margin-bottom: 2rem;
  border-bottom: 3px solid #e6c629; /* Underline with primary color */
  padding-bottom: 0.5rem;
}

.orders-container {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  margin-bottom: 1.5rem;
  padding: 1.5rem;
  transition: transform 0.3s, box-shadow 0.3s;
}

.orders-container:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
}

.orders-header {
  margin-bottom: 1rem; /* Reduce top margin for tighter layout */
}

.orders-nav {
  height: 50px;
  background-color: #e6c629;
  border: solid #e6c629;
  margin-bottom: 5%;
}

.checkout-button {
  background-color: #0c2a45;
  color: #ffffff;
  border: none;
  border-radius: 20px;
  padding: 12px 24px;
  cursor: pointer;
  font-weight: 600;
  transition: background-color 0.3s;
}

.checkout-button:hover {
  background-color: #1a3d66;
}

.order-item-quantity button {
  font-weight: 600; /* Bold font for emphasis */
  transition: background-color 0.3s;
}

.order-item-quantity button:hover {
  background-color: #f7d94c; /* Lighten color on hover */
}
.orders.orders-visible {
  display: block;
}

@media (max-width: 768px) {
  .orders {
    width: 100%;
    top: 10%;
    height: 76%;
    border-radius: 8px;
    border: none;
  }
  .orders-title {
    margin-left: 10%;
  }

  .orders-container {
    margin-left: 5%;
    width: 90%;
  }

  .close-orders {
    font-size: 12px;
    padding: 6px 10px;
    margin-left: 6%;
    display: none;
  }
  .orders-nav {
    display: none;
  }
}
