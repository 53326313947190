.cart {
  position: fixed;
  top: 0;
  left: 0;
  width: 25%;
  height: 100%;
  background-color: #ffffff;
  border-right: 1px solid #ccc;
  padding: 1rem;
  display: none;
  overflow-y: scroll;
  z-index: 100;
}

.cart-visible {
  display: block;
}

.close-cart {
}

.cart-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  border-bottom: 1px solid #ccc;
  padding-bottom: 1rem;
}

.cart-item img {
  max-width: 40px;
  max-height: 60px;
  object-fit: cover;
  margin-right: 1rem;
}

.cart-item-info {
  flex-grow: 1;
  text-align: left;
  font-size: 0.9rem;
}

.cart-item-quantity {
  font-size: 0.9rem;
}

.cart-item-quantity button {
  background-color: #e6c629;
  color: #ffffff;
  border: none;
  border-radius: 3px;
  padding: 2px 5px;
  cursor: pointer;
  margin-left: 5px;
}
.close-cart {
  margin-top: 3%;
  margin-right: 3%;
  background-color: #eee;
  color: #0c2a45;
  border: none;
  border-radius: 10%;
  font-size: 14px;
  font-weight: bold;
  padding: 4px 8px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}
.cart-empty {
  font-size: 1rem;
  color: #666;
  text-align: center;
  margin-top: 2rem;
}

.cart-title {
  font-size: 1.8rem; /* Bigger title for emphasis */
  color: #0c2a45;
  margin-bottom: 2rem;
  border-bottom: 3px solid #e6c629; /* Underline with primary color */
  padding-bottom: 0.5rem;
}
.cart-nav {
  height: 50px;
  background-color: #e6c629;
  border: solid #e6c629;
}

.cart-summary {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid #ccc;
  padding-top: 1rem;
}

.checkout-button {
  background-color: #e6c629;
  color: #ffffff;
  border: none;
  border-radius: 3px;
  padding: 8px 16px;
  margin-top: 1rem;
  cursor: pointer;
  font-size: 1rem;
  margin-bottom: 2rem;
}
.cart-header {
  margin-top: 5%;
}

@media (max-width: 768px) {
  .cart {
    width: 100%;
    max-height: 80%; /* Adjust based on your category height */
    bottom: 60px; /* Space for categories */
    overflow-y: auto;
  }

  .cart-title {
    font-size: 1.2rem;
    margin-right: 5%;
  }
  .cart-item-info {
    font-size: 0.8rem;
  }

  .cart-item-quantity {
    font-size: 0.8rem;
    padding-right: 13px;
  }
  .cart-item-quantity p {
    padding-right: 10px;
  }

  .close-cart {
    margin-right: 30px;
    font-size: 12px;
    padding: 4px 6px;
  }
}
