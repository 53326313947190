/* Styles.css or your preferred CSS file */
.upload-container {
  display: flex;
  flex-direction: column;
  max-width: 60%;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.upload-container button:hover {
  background-color: #0c2a45;
}

.uploaded-items {
  display: flex;
  flex-direction: column;
  align-items: center; /* Centering items horizontally */
  margin-top: 20px;
}

.uploaded-items li {
  list-style-type: none;
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  display: flex;
  align-items: center;
  margin-bottom: 10px; /* Adding space between items */
  width: 90%;
}
.edit-delete-button {
  min-width: 30%;
}

.upload-container .MuiFormControl-root,
.upload-container .MuiTextField-root,
.upload-container .MuiButton-root {
  margin-bottom: 16px; /* Adjust as needed */
}
.upload-container input[type="file"] {
  display: none;
}

.uploaded-items img {
  max-width: 50px;
  margin-right: 10px;
}

.uploaded-items p {
  flex-grow: 1;
}

.uploaded-items button {
  margin-left: 10px;
  padding: 5px 10px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.uploaded-items-button-edit-btn {
  background-color: #f0ad4e;
  color: #fff;
}

.uploaded-items-buttondelete-btn {
  background-color: #d9534f;
  color: #fff;
}

.uploaded-items button.edit-btn:hover {
  background-color: #ec971f;
}

.uploaded-items button.delete-btn:hover {
  background-color: #c9302c;
}

@media only screen and (max-width: 768px) {
  .upload-container {
    padding: 15px;
    max-width: 100%;
  }

  .uploaded-items li {
    flex-direction: column;
    align-items: center; /* Centering content of each item */
  }
  .edit-delete-button {
    flex-direction: row !important;
  }

  .uploaded-items img {
    margin-bottom: 10px;
    margin-right: 0;
  }
}
