.admin-orders {
  font-family: "Arial", sans-serif;
  background-color: #f4f4f4;
  padding: 2rem;
  margin: 0 auto;
  max-width: 800px;
  border-radius: 5px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

.orders-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding-bottom: 1rem;
}

.orders-title {
  color: #333;
  font-size: 1.5rem;
  font-weight: 600;
}

.orders-empty {
  color: #777;
  text-align: center;
  margin-top: 2rem;
}

.orders-container {
  background-color: #fff;
  margin-top: 2rem;
  padding: 1rem;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
}

.order-item {
  display: flex;
  align-items: center;
  margin-top: 1rem;
}

.order-item-img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  margin-right: 1rem;
  border-radius: 5px;
}

.order-item-desc {
  flex: 1;
}

.order-item-desc h4 {
  color: #333;
  font-size: 1.2rem;
}

.order-item-desc p {
  color: #555;
  font-size: 0.9rem;
}

/* Additional styles for better visuals */
h2,
h4,
p {
  margin: 0.5rem 0;
}

p {
  color: #666;
  font-size: 1rem;
}

/* Hover effects for order containers */
.orders-container:hover {
  background-color: #f9f9f9;
}

/* Enhancements for images and alignment */
.order-item-img {
  border: 1px solid #ddd;
}

@media only screen and (max-width: 768px) {
  .admin-orders {
    padding: 1rem;
    max-width: 100%;
    margin: 1rem 0.5rem; /* Added some margin on left and right to avoid touching the edges */
  }

  .orders-header {
    flex-direction: column; /* Stack elements vertically */
    text-align: center;
    padding-bottom: 0.5rem;
  }

  .orders-title {
    margin-bottom: 1rem; /* Space between title and other elements below it */
    font-size: 1.2rem; /* Adjusted font size for mobile */
  }

  .orders-container {
    padding: 0.5rem; /* Reduced padding */
  }

  .order-item {
    flex-direction: column; /* Stack image and description vertically */
    text-align: center; /* Center the content */
    margin-top: 1.5rem; /* Added some more margin between items for clarity */
  }

  .order-item-img {
    margin: 0 auto 0.5rem auto; /* Center image and add margin below */
  }

  .order-item-desc h4 {
    font-size: 1rem; /* Adjusted font size for mobile */
    margin-bottom: 0.5rem; /* Space between title and paragraph */
  }
}
