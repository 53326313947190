.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #e6c629;
}

.user-name {
  margin-right: 1rem;
}

.books-banner {
  margin-top: 1px;
  width: 100%;
  object-fit: cover;
}
.banner-container {
  position: relative;
}

.banner-dots {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
}

.banner-dot {
  width: 10px;
  height: 10px;
  background-color: #e6c629;
  opacity: 0.5;
  margin: 0 5px;
  border-radius: 50%;
  cursor: pointer;
  transition: opacity 0.3s;
}

.banner-dot.active {
  opacity: 1;
}

@media (max-width: 640px) {
  .books-banner {
    margin-top: 1px;
    margin-bottom: -2px;
    border-bottom: groove #0c2a45;
    /* display: none; */
  }
  .banner-dots {
    bottom: 6px;
  }

  .banner-dot {
    width: 5px;
    height: 5px;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
