@font-face {
  font-family: "Benedict";
  src: url("../../../assets/fonts/Benedict.otf");
}
.navbar {
  background-color: #e6c629;
  border-bottom: solid #0c2a45;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  position: relative;
  z-index: 1000;
}

.navbar a {
  color: white;
  text-decoration: none;
  padding: 8px 16px;
  display: block;
}

.navbar ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
}
.left-items,
.right-items {
  display: flex;
  align-items: center;
}

.center-item {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.profile-icon {
  position: relative;
  cursor: pointer;
  color: white;
}

.dropdown-menu-admin {
}

.user-name {
  display: inline-block;
  padding: 0px 15px 5px 5px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border: solid;
  border-left: hidden !important;
  border-color: #f1f1f1;
  margin-right: 0px !important;
  position: relative;
  top: -10px;
}

.profile-icon .dropdown-menu-admin {
  display: block;
}

.dropdown-menu-admin {
  position: absolute;
  right: -10px;
  top: calc(100% + 0.8rem);
  background-color: #e6c629;
  list-style-type: none;
  padding: 0.5rem 0;
  margin: 0;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
  min-width: 150px;
  width: 220px;
  z-index: 1;
  display: none;
}

.dropdown-menu-admin li {
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.dropdown-menu-admin li:hover {
  background-color: #0c2a45;
  color: white;
}
.user-name {
  margin-left: 10px;
  color: white;
  font-size: 1rem;
}
.container {
  overflow: visible; /* Makes sure the shadow isn't cut off */
}
.search-bar-admin {
  width: 300px;
  padding: 8px 12px;
  font-size: 16px;
  border-radius: 5px;
  margin-right: 20px;
  border: none !important;
}

.logo-container {
  padding: -5px;
}

.logo-admin {
  width: 40px;
  height: 40px;
  object-fit: cover;
}
.logo-admin:hover {
  background-color: none;
}
.logo-cart-container {
  position: relative;
  display: inline-block;
}
.hidden {
  display: none;
}
.cart-quantity {
  position: absolute;
  top: 35%;
  left: 57%;
  transform: translate(-50%, -50%);
  background-color: transparent;
  color: white;
  font-weight: bolder;
  border-radius: 50%;
  padding: 0.2rem 0.5rem;
  font-size: 0.6rem;
  z-index: 1;
  font-family: inherit;
}
.nav-items li {
  margin-right: 10px; /* Spacing between menu items */
}

.nav-items li:last-child {
  margin-right: 0; /* Remove margin for the last menu item */
}

.nav-items a {
  padding: 8px 16px; /* Padding for a larger click area and visual enhancement */
  border-radius: 20px; /* Rounded edges for a modern look */
  transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition for hover effects */
}
.user-button {
  margin-top: 10px;
  border: solid #fff;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  border-top: hidden;
  color: #ebd984;
}

.user-button:hover {
  background-color: #0c2a45; /* Darker background on hover */
  color: #e6c629; /* Light text color on hover for contrast */
}

@media only screen and (min-width: 415px) {
  .search-bar-admin {
    width: 350px;
  }
  .user-name {
    display: inline-block;
    position: relative;
    top: -10px;
    margin-right: 0px !important;
  }
  .profile-icon {
    margin-top: 13px;
    margin-right: 5px;
  }
}

/* Other styles remain the same */

.desktop-icon {
  display: inline;
}

.mobile-initials {
  display: none;
}

@media only screen and (max-width: 768px) {
  .logo-admin {
    display: inline-block;
  }

  .logo-cart-container {
    margin-left: -50px;
  }
  .search-bar-admin {
    max-width: 200px !important;
    margin-left: -10% !important;
  }

  .navbar a {
    padding: 5px 10px; /* reduced padding for links */
  }

  .nav-items li {
    margin-right: 5px; /* reduced margin between nav items */
  }
  .desktop-icon {
    display: none;
  }

  .mobile-initials {
    margin-top: -10px;
    margin-left: -10px;
    display: inline;
    border: 1px solid white;
    border-radius: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    width: 35px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    color: white;
    font-size: 1.1rem;
    font-weight: bold;
    background-color: #0c2a45;
    font-weight: 400;
  }
  .user-name {
    display: none;
  }
  .dropdown-menu-admin {
    transform: none; /* Remove the transformation */
    margin-right: -10px;
    top: calc(100% + 2.3rem); /* Adjust space between the navbar and dropdown */
    width: 85%; /* Adjust the dropdown width if needed, or you can set a max-width */
    box-sizing: border-box; /* Ensuring padding and borders are included in total width */
  }

  .user-button {
    display: none;
  }
}
@media only screen and (min-width: 769px) {
  .dropdown-menu-admin .upload-item-admin,
  .dropdown-menu-admin .orders-item-admin {
    display: none;
  }
}

@media (max-width: 640px) {
  .navbar {
    background-color: #e6c629;
  }

  .dropdown-menu-admin {
    background-color: #e6c629;
    border: 2px solid #fff;
    border-top: hidden;
  }
}
@media only screen and (max-width: 455px) {
  .profile-icon {
    margin-top: 20px;
    margin-right: 10px; /* Adjusted margin for better spacing */
  }
}
