@font-face {
  font-family: "Benedict";
  src: url("../../../assets/fonts/Benedict.otf");
}
/* Reset some default styling */

h1,
h2,
h3,
h4,
h5,
p,
button {
  margin: 0;
  padding: 0;
}

/* Styling for the scroll bar */
::-webkit-scrollbar {
  width: 0px;
}

/* General layout and styling */

.book-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  padding: 20px;
  scroll-behavior: smooth;
  max-height: 100vh;
  overflow-y: auto;
}

.book-card {
  background-color: #ffffffaa;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  width: 200px;
  margin: 20px;
  transition: all 0.3s ease-in-out;
  opacity: 0;
  transform: translateY(40px);
}

.book-card.animate {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.3s ease, transform 0.4s ease;
}
.book-card:hover {
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  transform: translateY(-4px);
}

.book-card img {
  border-radius: 4px;
  margin-bottom: 16px;
  max-width: 100%;
  object-fit: cover;
  height: 150px;
  width: auto;
}

.book-card h3 {
  font-size: 18px;
  margin-bottom: 4px;
  font-weight: 600;
  text-align: center;
  color: #e6c629;
}

.book-card p {
  font-size: 14px;
  margin-bottom: 4px;
  text-align: center;
  color: #777;
  font-family: "Benedict", sans-serif;
}

.book-card .price {
  font-size: 16px;
  font-weight: 600;
  color: #2196f3;
  margin-bottom: 8px;
}

.book-card button {
  background-color: #0c2a45;
  border: 1px groove #e6c629;
  border-top: hidden;
  border-bottom: hidden;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  padding: 8px 16px;
  transition: background-color 0.2s ease-in-out, transform 0.2s ease-in-out;
  margin-top: auto;
}

.book-card button:hover {
  background-color: #e6c629;
}

.categories {
  text-align: center;
  color: #ffffff;
  margin-bottom: 1rem;
  padding: 1%;
  /* margin-top: -1.3rem; */
  /* text-transform: uppercase; */
  background: #0c2a45;
  border-top: hidden;
  padding-top: 2%;
}

.popup-overlay {
  position: fixed;
  top: 8px;
  left: 0;
  width: 100%;
  height: 95%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.popup {
  background-color: #fff;
  border-radius: 5px;
  padding: 2rem;
  max-width: 90%;
  max-height: 90%;
  overflow-y: auto;
  position: relative;
  color: black;
}

.close-popup {
  background-color: #e6c629;
  color: #fff;
  border: none;
  border-radius: 50%;
  font-size: 14px;
  padding: 4px 8px;
  margin-top: 1%;
  float: right;
  cursor: pointer;
}

.cart-nav {
  height: 50px;
  background-color: #e6c629;
  border: solid #e6c629;
}

.close-cart {
  margin-top: 3%;
  margin-right: 3%;
  background-color: #eee;
  color: #0c2a45;
  border: none;
  border-radius: 10%;
  font-size: 14px;
  font-weight: bold;
  padding: 4px 8px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}
h1 {
  font-size: xxx-large;
  font-weight: 560;
  font-family: Benedict;
  margin-bottom: 2px;
}

.category-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 5px;
}

.category-buttons button {
  border: 1px solid #e6c629;
  margin: 0 10px;
  padding: 8px 20px;
  font-size: 16px;
  font-weight: 600;
  background-color: transparent;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  border-top: hidden;
}

.category-buttons button:hover {
  background-color: #e6c629;
  color: #0c2a45;
}

.category-buttons button.active {
  background: #e6c629;
  color: #fff;
}

.space {
  height: 4px;
}

h1 {
  font-size: xxx-large;
  font-weight: 560;
  font-family: Benedict;
  margin-bottom: 2px;
}

.category-buttons button:first-child {
  margin-left: 0;
}

.category-buttons button:last-child {
  margin-right: 0;
}
.flip-container {
  transform-style: preserve-3d;
  transition: transform 0.5s;
  width: 100%;
  height: 300px;
  position: relative;
}

.front,
.back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  will-change: transform;
  top: 0;
  left: 0;
  transform-style: preserve-3d;
}

.front {
  transform: rotateY(0deg);
}

.back {
  transform: rotateY(180deg);
}

.flip-container.flipped .front {
  transform: rotateY(180deg);
  display: none;
}

.flip-container.flipped .back {
  transform: rotateY(360deg);
  display: block;
  position: relative;
}
.title-line {
  width: 60%; /* or your desired width */
  margin: 8px auto;
  border: 0.5px solid #e6c629; /* color of the line */
}
.category-logo {
  display: none;
}
.item-title {
  font-family: "sans-serif";
  color: black;
}
.discription {
  font-family: sans-serif !important;
  font-size: 12px !important;
}

/* Add this media query and keyframes animation */
@media (max-width: 640px) {
  .popup-overlay {
    top: 0px;
  }

  .categories {
    text-align: center;
    color: #ffffff;
    background-color: #0c2a45;
    position: fixed; /* makes the categories stick to the bottom */
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 2;
    margin-top: 50px;
    margin-bottom: 0px !important;
  }
  .catagory-name {
    margin-top: 8px;
  }
  .category-logo {
    margin: 0 10px; /* Give some margin to separate it from the buttons */
    vertical-align: middle;
    width: 40px;
    height: 40px;
    display: inline;
  }

  .category-buttons button {
    padding: 8px 10px;
    font-size: 10px;
    font-weight: 600;
  }
  .scroll-buttons-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    left: 0;
    pointer-events: none;
    z-index: 3;
  }

  .scroll-button {
    background-color: transparent;
    color: #e6c629;
    border: none;
    cursor: pointer;
    outline: none;
    padding: 10px;
    transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
    border-radius: 50%;
    pointer-events: all;
  }

  .scroll-button:hover {
    transform: scale(1.1);
    background-color: rgba(0, 0, 0, 0.2);
  }

  .book-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: scroll;
    justify-content: center;
    align-items: center;
    width: 100%;
    scroll-snap-type: x mandatory;
    scroll-padding-left: 17%;
    -webkit-overflow-scrolling: touch;
    padding: 10px 0;
    perspective: 1000px;
  }

  .book-card {
    perspective: 1000px;
    flex-shrink: 0;
    flex-direction: column;
    border-radius: 10px;
    align-items: center;
    padding: 10px;
    width: 180px;
    margin: 10px;
    scroll-snap-align: start;
    position: relative;
    transform-style: preserve-3d;
    transition: box-shadow 0.2s ease-in-out, transform 0.3s ease-in-out;
  }

  .padding-start {
    width: calc(50% + 1900px);
    flex-shrink: 0;
  }

  .book-card-container {
    display: inline-block;
  }

  h1 {
    font-size: 1.5rem;
    font-weight: 550;
    color: black;
    margin-bottom: 0px;
    margin-top: 0%;
  }
}

@media (min-width: 641px) {
  .scroll-button {
    display: none;
  }
  .scroll-buttons-container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }

  .scroll-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    padding: 10px;
    transition: transform 0.3s ease-in-out;
  }
}

@media only screen and (max-width: 1000px) {
  .popup {
    padding-top: 3%;
    margin-top: 0%;
  }
}
